import {
  ContactId,
  ShipReplacementTo,
  ReplacementType,
  TypeOfLoss,
} from "../FieldIds";
import warningIcon from "../../../assets/warning-icon.svg";
import { useState } from "react";
import StyledRadioButton from "../../StyledRadioButton";
import Select from "../../common/Select";
import { FormState } from "../LostStolen";
import styles from "./LostStolenForm.module.css";
import BaseModal from "../../common/modals/BaseModal/BaseModal";
import StyledButton from "../../common/buttons/StyledButton";
import PhoneNumberInput from "../../common/PhoneNumberInput";
import StyledInput from "../../common/inputs/StyledInput";
import StyledZipcodeInput from "../../common/StyledZipcodeInput";
import classNames from "classnames";
import DateInput from "../../common/DateInput";
import { LostStolenOptionsResponse } from "../../../lib/api/models";

const strings = {
  contact: "Contact",
  select: "Select",
  cardmember: "Cardmember",
  authUser: "Authorized user",
  parent: "Parent",
  thirdPartyKnown: "3rd party known to CM",
  thirdPartyUnknown: "3rd party unknown to CM",
  merchant: "Merchant",
  other: "Other",
  cardInPossession: "Card in possession?",
  yes: "Yes",
  no: "No",
  typeOfLoss: "Type of loss",
  lostCard: "Lost card",
  stolen: "Stolen",
  notReceived: "Not received",
  fraudApp: "Fraud app",
  counterfeit: "Counterfeit",
  accountTakeover: "Account takeover",
  allMailPhone: "All mail and phone",
  fraudOnAccount: "Fraud on account?",
  transferToSecurity: "Xfer to sec?",
  cid: "CID",
  bestWayToContact: "Best way to contact",
  home: "Home",
  work: "Work",
  billingAddress: "Billing address",
  temporaryAddress: "Temporary address",
  shipReplacementTo: "Ship replacement to",
  addressChangeToday: "Address change today?",
  newAddressVerify: "New address verify w/ security",
  tempAddressVerify: "Temporary address verify w/ sec",
  reasonForTempAddress: "Reason for temp address",
  homeAddress: "Home address",
  parentsAddress: "Parents address",
  schoolAddress: "School address",
  workAddress: "Work address",
  vacation: "Vacation/traveling",
  securityIssue: "Security issue w/ billing addr",
  tempAddress1: "Temporary address 1",
  tempAddress2: "Temporary address 2",
  zip: "Zip",
  city: "City",
  state: "State",
  replacementOptions: "Replacement Options",
  standard: "Standard",
  rushFedex: "Rush FedEx",
  rushPostalExpress: "Rush Postal Express",
  waiveReplacementFee: "Waive replacement fee",
  waiveRushFee: "Waive rush fee",
  dateLost: "Date lost",
  lossLocation: "Loss location",
  auto: "Auto",
  office: "Office",
  store: "Store",
  rural: "Rural",
  bar: "Bar",
  hotel: "Hotel/motel",
  unknown: "Unknown",
  stateOfLoss: "US state of loss",
  pinCompromised: "Pin compromised",
  fraudCharges: "Fraud charges",
  submit: "Submit",
  transferToSecurityModalTitle: "Transfer to Security",
  transferToSecurityModalDescription:
    "If Transfer for Security 6777 was successful, click “Close Tool” to End.",
  closeTool: "Close Tool",
  goBack: "Go Back",
  ok: "OK",
};

const CID_VALUE_LENGTH = 10;

type Props = {
  formState: FormState;
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
  closeTool: () => void;
  options: LostStolenOptionsResponse;
  onSubmit: () => void;
  isSubmitting: boolean;
};

export default function LostStolenForm({
  formState,
  setFormState,
  closeTool,
  options,
  onSubmit,
  isSubmitting,
}: Props) {
  const [showTransferToSecurityModal, setShowTransferToSecurityModal] =
    useState(false);

  return (
    <>
      <Select
        className={classNames(styles.row, styles.shortWidth)}
        disabled={isSubmitting}
        options={options.contactTypes.map((ct) => ({
          value: ct.id as ContactId,
          label: ct.value,
        }))}
        selectedValue={formState.contactId}
        onChange={(value: ContactId) => {
          setFormState({
            contactId: value,
            typeOfLoss:
              value === ContactId.ThirdPartyUnknownToCM ||
              value === ContactId.Merchant
                ? TypeOfLoss.LostCard
                : undefined,
          });
          if (
            value === ContactId.AuthUser ||
            value === ContactId.ThirdPartyUnknownToCM ||
            value === ContactId.Merchant
          ) {
            // TODO: get/show script
          }
        }}
        label={strings.contact}
        labelClassName={styles.label}
        placeholder={strings.select}
      />
      {formState.contactId === ContactId.Merchant ||
      formState.contactId === ContactId.ThirdPartyUnknownToCM ? (
        <StyledButton className={styles.ok} onClick={closeTool}>
          {strings.ok}
        </StyledButton>
      ) : null}
      {formState.contactId === ContactId.Cardmember ||
      formState.contactId === ContactId.AuthUser ||
      formState.contactId === ContactId.Parent ||
      formState.contactId === ContactId.ThirdPartyKnownToCM ||
      formState.contactId === ContactId.Other ? (
        <div className={styles.row}>
          <div className={styles.label}>{strings.cardInPossession}</div>
          <StyledRadioButton
            className={styles.leftRadioInput}
            disabled={isSubmitting}
            checked={!!formState.cardInPossession}
            label={strings.yes}
            onChange={() =>
              setFormState((prev) => ({
                contactId: prev.contactId,
                cardInPossession: true,
              }))
            }
          />
          <StyledRadioButton
            disabled={isSubmitting}
            checked={
              formState.cardInPossession !== undefined &&
              !formState.cardInPossession
            }
            label={strings.no}
            onChange={() =>
              setFormState((prev) => ({
                contactId: prev.contactId,
                cardInPossession: false,
              }))
            }
          />
        </div>
      ) : null}
      {formState.cardInPossession !== undefined ? (
        <Select
          className={classNames(styles.row, styles.shortWidth)}
          options={
            formState.cardInPossession
              ? options.lossTypes
                  .filter(
                    (lt) =>
                      lt.id === TypeOfLoss.Counterfeit ||
                      lt.id === TypeOfLoss.AccountTakeover ||
                      lt.id === TypeOfLoss.AllMailAndPhone,
                  )
                  .map((lt) => ({
                    value: lt.id as TypeOfLoss,
                    label: lt.value,
                  }))
              : options.lossTypes
                  .filter(
                    (lt) =>
                      lt.id === TypeOfLoss.LostCard ||
                      lt.id === TypeOfLoss.Stolen ||
                      lt.id === TypeOfLoss.NotReceived ||
                      lt.id === TypeOfLoss.FraudApp,
                  )
                  .map((lt) => ({
                    value: lt.id as TypeOfLoss,
                    label: lt.value,
                  }))
          }
          selectedValue={formState.typeOfLoss}
          onChange={(value: TypeOfLoss) => {
            if (
              value === TypeOfLoss.AccountTakeover ||
              value === TypeOfLoss.Counterfeit ||
              value === TypeOfLoss.AllMailAndPhone ||
              value === TypeOfLoss.FraudApp
            ) {
              setFormState((prev) => ({
                contactId: prev.contactId,
                cardInPossession: prev.cardInPossession,
                typeOfLoss: value,
                fraudOnAccount: true,
              }));
            } else {
              setFormState((prev) => ({
                contactId: prev.contactId,
                cardInPossession: prev.cardInPossession,
                typeOfLoss: value,
              }));
            }
            // TODO: get/show script
          }}
          label={strings.typeOfLoss}
          labelClassName={styles.label}
          placeholder={strings.select}
          disabled={isSubmitting}
        />
      ) : null}
      {formState.cardInPossession !== undefined && formState.typeOfLoss ? (
        <div className={styles.row}>
          <div className={styles.label}>{strings.fraudOnAccount}</div>
          <StyledRadioButton
            className={styles.leftRadioInput}
            checked={!!formState.fraudOnAccount}
            label={strings.yes}
            disabled={
              formState.typeOfLoss === TypeOfLoss.AccountTakeover ||
              formState.typeOfLoss === TypeOfLoss.Counterfeit ||
              formState.typeOfLoss === TypeOfLoss.AllMailAndPhone ||
              formState.typeOfLoss === TypeOfLoss.FraudApp ||
              isSubmitting
            }
            onChange={() =>
              setFormState((prev) => ({
                contactId: prev.contactId,
                cardInPossession: prev.cardInPossession,
                typeOfLoss: prev.typeOfLoss,
                fraudOnAccount: true,
              }))
            }
          />
          <StyledRadioButton
            checked={
              formState.fraudOnAccount !== undefined &&
              !formState.fraudOnAccount
            }
            label={strings.no}
            disabled={
              formState.typeOfLoss === TypeOfLoss.AccountTakeover ||
              formState.typeOfLoss === TypeOfLoss.Counterfeit ||
              formState.typeOfLoss === TypeOfLoss.AllMailAndPhone ||
              formState.typeOfLoss === TypeOfLoss.FraudApp ||
              isSubmitting
            }
            onChange={() => {
              setFormState((prev) => ({
                contactId: prev.contactId,
                cardInPossession: prev.cardInPossession,
                typeOfLoss: prev.typeOfLoss,
                fraudOnAccount: false,
              }));
              // TODO: show script
            }}
          />
        </div>
      ) : null}
      {formState.fraudOnAccount ? (
        <div className={styles.row}>
          <div className={styles.label}>{strings.transferToSecurity}</div>
          <StyledRadioButton
            className={styles.leftRadioInput}
            disabled={isSubmitting}
            checked={!!formState.transferToSecurity}
            label={strings.yes}
            onChange={() => {
              setFormState((prev) => ({
                contactId: prev.contactId,
                cardInPossession: prev.cardInPossession,
                typeOfLoss: prev.typeOfLoss,
                fraudOnAccount: prev.fraudOnAccount,
                transferToSecurity: true,
              }));
              setShowTransferToSecurityModal(true);
            }}
          />
          <StyledRadioButton
            disabled={isSubmitting}
            checked={
              formState.transferToSecurity !== undefined &&
              !formState.transferToSecurity
            }
            label={strings.no}
            onChange={() =>
              setFormState((prev) => ({
                contactId: prev.contactId,
                cardInPossession: prev.cardInPossession,
                typeOfLoss: prev.typeOfLoss,
                fraudOnAccount: prev.fraudOnAccount,
                transferToSecurity: false,
              }))
            }
          />
        </div>
      ) : null}
      {formState.transferToSecurity !== undefined &&
      !formState.transferToSecurity ? (
        <>
          <div className={classNames(styles.row, styles.shortWidth)}>
            <div className={styles.label}>{strings.cid}</div>
            <PhoneNumberInput
              disabled={isSubmitting}
              value={formState.cid ?? ""}
              onChange={(value) =>
                setFormState((prev) => ({
                  contactId: prev.contactId,
                  cardInPossession: prev.cardInPossession,
                  typeOfLoss: prev.typeOfLoss,
                  fraudOnAccount: prev.fraudOnAccount,
                  transferToSecurity: prev.transferToSecurity,
                  cid: value,
                  bestWayToContact: prev.bestWayToContact,
                }))
              }
            />
          </div>
          <Select
            className={classNames(styles.row, styles.shortWidth)}
            disabled={isSubmitting}
            options={options.waysToContact.map((wtc) => ({
              value: wtc.id,
              label: wtc.value,
            }))}
            selectedValue={formState.bestWayToContact}
            onChange={(value) =>
              setFormState((prev) => ({
                contactId: prev.contactId,
                cardInPossession: prev.cardInPossession,
                typeOfLoss: prev.typeOfLoss,
                fraudOnAccount: prev.fraudOnAccount,
                transferToSecurity: prev.transferToSecurity,
                cid: prev.cid,
                bestWayToContact: value,
              }))
            }
            label={strings.bestWayToContact}
            labelClassName={styles.label}
            placeholder={strings.select}
          />
        </>
      ) : null}
      {(formState.fraudOnAccount !== undefined && !formState.fraudOnAccount) ||
      formState.cid?.length === CID_VALUE_LENGTH ||
      formState.bestWayToContact ? (
        <Select
          className={classNames(styles.row, styles.shortWidth)}
          disabled={isSubmitting}
          options={options.shipToTypes.map((stt) => ({
            label: stt.value,
            value: stt.id as ShipReplacementTo,
          }))}
          selectedValue={formState.shipReplacementTo}
          onChange={(value: ShipReplacementTo) =>
            setFormState((prev) => ({
              contactId: prev.contactId,
              cardInPossession: prev.cardInPossession,
              typeOfLoss: prev.typeOfLoss,
              fraudOnAccount: prev.fraudOnAccount,
              transferToSecurity: prev.transferToSecurity,
              cid: prev.cid,
              bestWayToContact: prev.bestWayToContact,
              shipReplacementTo: value,
            }))
          }
          label={strings.shipReplacementTo}
          labelClassName={styles.label}
          placeholder={strings.select}
        />
      ) : null}
      {formState.shipReplacementTo === ShipReplacementTo.Billing ? (
        <div className={styles.row}>
          <div className={styles.label}>{strings.addressChangeToday}</div>
          <StyledRadioButton
            className={styles.leftRadioInput}
            disabled={isSubmitting}
            checked={!!formState.addressChangeToday}
            label={strings.yes}
            onChange={() =>
              setFormState((prev) => ({
                contactId: prev.contactId,
                cardInPossession: prev.cardInPossession,
                typeOfLoss: prev.typeOfLoss,
                fraudOnAccount: prev.fraudOnAccount,
                transferToSecurity: prev.transferToSecurity,
                cid: prev.cid,
                bestWayToContact: prev.bestWayToContact,
                shipReplacementTo: prev.shipReplacementTo,
                addressChangeToday: true,
              }))
            }
          />
          <StyledRadioButton
            disabled={isSubmitting}
            checked={
              formState.addressChangeToday !== undefined &&
              !formState.addressChangeToday
            }
            label={strings.no}
            onChange={() =>
              setFormState((prev) => ({
                contactId: prev.contactId,
                cardInPossession: prev.cardInPossession,
                typeOfLoss: prev.typeOfLoss,
                fraudOnAccount: prev.fraudOnAccount,
                transferToSecurity: prev.transferToSecurity,
                cid: prev.cid,
                bestWayToContact: prev.bestWayToContact,
                shipReplacementTo: prev.shipReplacementTo,
                addressChangeToday: false,
              }))
            }
          />
        </div>
      ) : null}
      {formState.addressChangeToday ? (
        <div className={styles.row}>
          <div className={styles.label}>{strings.newAddressVerify}</div>
          <StyledRadioButton
            className={styles.leftRadioInput}
            disabled={isSubmitting}
            checked={!!formState.newAddressVerify}
            label={strings.yes}
            onChange={() =>
              setFormState((prev) => ({
                contactId: prev.contactId,
                cardInPossession: prev.cardInPossession,
                typeOfLoss: prev.typeOfLoss,
                fraudOnAccount: prev.fraudOnAccount,
                transferToSecurity: prev.transferToSecurity,
                cid: prev.cid,
                bestWayToContact: prev.bestWayToContact,
                shipReplacementTo: prev.shipReplacementTo,
                addressChangeToday: prev.addressChangeToday,
                newAddressVerify: true,
              }))
            }
          />
          <StyledRadioButton
            disabled={isSubmitting}
            checked={
              formState.newAddressVerify !== undefined &&
              !formState.newAddressVerify
            }
            label={strings.no}
            onChange={() =>
              setFormState((prev) => ({
                contactId: prev.contactId,
                cardInPossession: prev.cardInPossession,
                typeOfLoss: prev.typeOfLoss,
                fraudOnAccount: prev.fraudOnAccount,
                transferToSecurity: prev.transferToSecurity,
                cid: prev.cid,
                bestWayToContact: prev.bestWayToContact,
                shipReplacementTo: prev.shipReplacementTo,
                addressChangeToday: prev.addressChangeToday,
                newAddressVerify: false,
              }))
            }
          />
        </div>
      ) : null}
      {formState.newAddressVerify !== undefined &&
      !formState.newAddressVerify ? (
        <div className={classNames(styles.row, styles.shortWidth)}>
          <div className={styles.label}>{strings.cid}</div>
          <PhoneNumberInput
            disabled={isSubmitting}
            value={formState.cid2 ?? ""}
            onChange={(value) =>
              setFormState((prev) => ({
                contactId: prev.contactId,
                cardInPossession: prev.cardInPossession,
                typeOfLoss: prev.typeOfLoss,
                fraudOnAccount: prev.fraudOnAccount,
                transferToSecurity: prev.transferToSecurity,
                cid: prev.cid,
                bestWayToContact: prev.bestWayToContact,
                shipReplacementTo: prev.shipReplacementTo,
                addressChangeToday: prev.addressChangeToday,
                newAddressVerify: prev.newAddressVerify,
                cid2: value,
              }))
            }
          />
        </div>
      ) : null}
      {formState.shipReplacementTo === ShipReplacementTo.Temporary ? (
        <div className={styles.row}>
          <div className={styles.label}>{strings.tempAddressVerify}</div>
          <StyledRadioButton
            className={styles.leftRadioInput}
            disabled={isSubmitting}
            checked={!!formState.tempAddressVerify}
            label={strings.yes}
            onChange={() =>
              setFormState((prev) => ({
                contactId: prev.contactId,
                cardInPossession: prev.cardInPossession,
                typeOfLoss: prev.typeOfLoss,
                fraudOnAccount: prev.fraudOnAccount,
                transferToSecurity: prev.transferToSecurity,
                cid: prev.cid,
                bestWayToContact: prev.bestWayToContact,
                shipReplacementTo: prev.shipReplacementTo,
                tempAddressVerify: true,
              }))
            }
          />
          <StyledRadioButton
            disabled={isSubmitting}
            checked={
              formState.tempAddressVerify !== undefined &&
              !formState.tempAddressVerify
            }
            label={strings.no}
            onChange={() =>
              setFormState((prev) => ({
                contactId: prev.contactId,
                cardInPossession: prev.cardInPossession,
                typeOfLoss: prev.typeOfLoss,
                fraudOnAccount: prev.fraudOnAccount,
                transferToSecurity: prev.transferToSecurity,
                cid: prev.cid,
                bestWayToContact: prev.bestWayToContact,
                shipReplacementTo: prev.shipReplacementTo,
                tempAddressVerify: false,
              }))
            }
          />
        </div>
      ) : null}
      {formState.tempAddressVerify !== undefined &&
      !formState.tempAddressVerify ? (
        <div className={classNames(styles.row, styles.shortWidth)}>
          <div className={styles.label}>{strings.cid}</div>
          <PhoneNumberInput
            disabled={isSubmitting}
            value={formState.cid2 ?? ""}
            onChange={(value) =>
              setFormState((prev) => ({
                contactId: prev.contactId,
                cardInPossession: prev.cardInPossession,
                typeOfLoss: prev.typeOfLoss,
                fraudOnAccount: prev.fraudOnAccount,
                transferToSecurity: prev.transferToSecurity,
                cid: prev.cid,
                bestWayToContact: prev.bestWayToContact,
                shipReplacementTo: prev.shipReplacementTo,
                tempAddressVerify: prev.tempAddressVerify,
                cid2: value,
              }))
            }
          />
        </div>
      ) : null}
      {formState.tempAddressVerify !== undefined &&
      !formState.tempAddressVerify &&
      formState.cid2?.length === CID_VALUE_LENGTH ? (
        <Select
          className={classNames(styles.row, styles.shortWidth)}
          disabled={isSubmitting}
          options={options.tempAddressReasons.map((tar) => ({
            value: tar.id,
            label: tar.value,
          }))}
          selectedValue={formState.reasonForTempAddress}
          onChange={(value) =>
            setFormState((prev) => ({
              contactId: prev.contactId,
              cardInPossession: prev.cardInPossession,
              typeOfLoss: prev.typeOfLoss,
              fraudOnAccount: prev.fraudOnAccount,
              transferToSecurity: prev.transferToSecurity,
              cid: prev.cid,
              bestWayToContact: prev.bestWayToContact,
              shipReplacementTo: prev.shipReplacementTo,
              tempAddressVerify: prev.tempAddressVerify,
              cid2: prev.cid2,
              reasonForTempAddress: value,
            }))
          }
          label={strings.reasonForTempAddress}
          labelClassName={styles.label}
          placeholder={strings.select}
        />
      ) : null}
      {formState.tempAddressVerify ||
      formState.reasonForTempAddress !== undefined ? (
        <>
          <div className={styles.row}>
            <StyledInput
              disabled={isSubmitting}
              labelClassName={styles.label}
              label={strings.tempAddress1}
              value={formState.tempAddress?.address1 ?? ""}
              onChange={(e) =>
                setFormState((prev) => ({
                  contactId: prev.contactId,
                  cardInPossession: prev.cardInPossession,
                  typeOfLoss: prev.typeOfLoss,
                  fraudOnAccount: prev.fraudOnAccount,
                  transferToSecurity: prev.transferToSecurity,
                  cid: prev.cid,
                  bestWayToContact: prev.bestWayToContact,
                  shipReplacementTo: prev.shipReplacementTo,
                  tempAddressVerify: prev.tempAddressVerify,
                  cid2: prev.cid2,
                  reasonForTempAddress: prev.reasonForTempAddress,
                  tempAddress: {
                    ...prev.tempAddress,
                    address1: e.target.value,
                  },
                }))
              }
            />
          </div>
          <div className={styles.row}>
            <StyledInput
              disabled={isSubmitting}
              labelClassName={styles.label}
              label={strings.tempAddress2}
              value={formState.tempAddress?.address2 ?? ""}
              onChange={(e) =>
                setFormState((prev) => ({
                  contactId: prev.contactId,
                  cardInPossession: prev.cardInPossession,
                  typeOfLoss: prev.typeOfLoss,
                  fraudOnAccount: prev.fraudOnAccount,
                  transferToSecurity: prev.transferToSecurity,
                  cid: prev.cid,
                  bestWayToContact: prev.bestWayToContact,
                  shipReplacementTo: prev.shipReplacementTo,
                  tempAddressVerify: prev.tempAddressVerify,
                  reasonForTempAddress: prev.reasonForTempAddress,
                  cid2: prev.cid2,
                  tempAddress: {
                    ...prev.tempAddress,
                    address2: e.target.value,
                  },
                }))
              }
            />
          </div>
          <div className={classNames(styles.row, styles.tempAddressBottomRow)}>
            <StyledInput
              className={styles.tempAddressBottomInput}
              disabled={isSubmitting}
              labelClassName={styles.label}
              label={strings.city}
              value={formState.tempAddress?.city ?? ""}
              onChange={(e) =>
                setFormState((prev) => ({
                  contactId: prev.contactId,
                  cardInPossession: prev.cardInPossession,
                  typeOfLoss: prev.typeOfLoss,
                  fraudOnAccount: prev.fraudOnAccount,
                  transferToSecurity: prev.transferToSecurity,
                  cid: prev.cid,
                  bestWayToContact: prev.bestWayToContact,
                  shipReplacementTo: prev.shipReplacementTo,
                  tempAddressVerify: prev.tempAddressVerify,
                  reasonForTempAddress: prev.reasonForTempAddress,
                  cid2: prev.cid2,
                  tempAddress: {
                    ...prev.tempAddress,
                    city: e.target.value,
                  },
                }))
              }
            />
            <Select
              className={styles.tempAddressBottomInput}
              disabled={isSubmitting}
              label={strings.state}
              labelClassName={styles.label}
              placeholder={strings.select}
              options={options.lossStates.map((ls) => ({
                value: ls.id,
                label: ls.value,
              }))}
              selectedValue={formState.tempAddress?.state}
              onChange={(value) =>
                setFormState((prev) => ({
                  contactId: prev.contactId,
                  cardInPossession: prev.cardInPossession,
                  typeOfLoss: prev.typeOfLoss,
                  fraudOnAccount: prev.fraudOnAccount,
                  transferToSecurity: prev.transferToSecurity,
                  cid: prev.cid,
                  bestWayToContact: prev.bestWayToContact,
                  shipReplacementTo: prev.shipReplacementTo,
                  tempAddressVerify: prev.tempAddressVerify,
                  reasonForTempAddress: prev.reasonForTempAddress,
                  cid2: prev.cid2,
                  tempAddress: {
                    ...prev.tempAddress,
                    state: value,
                  },
                }))
              }
            />
            <StyledZipcodeInput
              className={styles.tempAddressBottomInput}
              disabled={isSubmitting}
              label={strings.zip}
              labelClassName={styles.label}
              value={formState.tempAddress?.zip ?? ""}
              onChange={(value) =>
                setFormState((prev) => ({
                  contactId: prev.contactId,
                  cardInPossession: prev.cardInPossession,
                  typeOfLoss: prev.typeOfLoss,
                  fraudOnAccount: prev.fraudOnAccount,
                  transferToSecurity: prev.transferToSecurity,
                  cid: prev.cid,
                  bestWayToContact: prev.bestWayToContact,
                  shipReplacementTo: prev.shipReplacementTo,
                  tempAddressVerify: prev.tempAddressVerify,
                  reasonForTempAddress: prev.reasonForTempAddress,
                  cid2: prev.cid2,
                  tempAddress: {
                    ...prev.tempAddress,
                    zip: value,
                  },
                }))
              }
            />
          </div>
        </>
      ) : null}
      {(formState.addressChangeToday !== undefined &&
        !formState.addressChangeToday) ||
      !!formState.newAddressVerify ||
      (formState.newAddressVerify !== undefined &&
        !formState.newAddressVerify &&
        formState.cid2?.length === CID_VALUE_LENGTH) ||
      (formState.tempAddress?.address1 &&
        formState.tempAddress?.city &&
        formState.tempAddress?.state &&
        formState.tempAddress?.zip) ? (
        <Select
          className={classNames(styles.row, styles.shortWidth)}
          disabled={isSubmitting}
          label={strings.replacementOptions}
          labelClassName={styles.label}
          placeholder={strings.select}
          options={options.replacementTypes.map((rt) => ({
            value: rt.id as ReplacementType,
            label: rt.value,
          }))}
          selectedValue={formState.replacementOption}
          onChange={(value: ReplacementType) =>
            setFormState((prev) => ({
              contactId: prev.contactId,
              cardInPossession: prev.cardInPossession,
              typeOfLoss: prev.typeOfLoss,
              fraudOnAccount: prev.fraudOnAccount,
              transferToSecurity: prev.transferToSecurity,
              cid: prev.cid,
              bestWayToContact: prev.bestWayToContact,
              shipReplacementTo: prev.shipReplacementTo,
              addressChangeToday: prev.addressChangeToday,
              newAddressVerify: prev.newAddressVerify,
              tempAddressVerify: prev.tempAddressVerify,
              reasonForTempAddress: prev.reasonForTempAddress,
              cid2: prev.cid2,
              tempAddress: prev.tempAddress,
              replacementOption: value,
            }))
          }
        />
      ) : null}
      {formState.replacementOption ? (
        <>
          <div className={styles.row}>
            <div className={styles.label}>{strings.waiveReplacementFee}</div>
            <StyledRadioButton
              className={styles.leftRadioInput}
              disabled={isSubmitting}
              checked={!!formState.waiveReplacementFee}
              label={strings.yes}
              onChange={() =>
                setFormState((prev) => ({
                  contactId: prev.contactId,
                  cardInPossession: prev.cardInPossession,
                  typeOfLoss: prev.typeOfLoss,
                  fraudOnAccount: prev.fraudOnAccount,
                  transferToSecurity: prev.transferToSecurity,
                  cid: prev.cid,
                  bestWayToContact: prev.bestWayToContact,
                  shipReplacementTo: prev.shipReplacementTo,
                  addressChangeToday: prev.addressChangeToday,
                  newAddressVerify: prev.newAddressVerify,
                  tempAddressVerify: prev.tempAddressVerify,
                  reasonForTempAddress: prev.reasonForTempAddress,
                  cid2: prev.cid2,
                  tempAddress: prev.tempAddress,
                  replacementOption: prev.replacementOption,
                  waiveReplacementFee: true,
                  waiveRushFee: prev.waiveRushFee,
                  dateLost: prev.dateLost,
                  lossLocation: prev.lossLocation,
                  stateOfLoss: prev.stateOfLoss,
                  pinCompromised: prev.pinCompromised,
                  fraudCharges: prev.fraudCharges,
                }))
              }
            />
            <StyledRadioButton
              disabled={isSubmitting}
              checked={
                formState.waiveReplacementFee !== undefined &&
                !formState.waiveReplacementFee
              }
              label={strings.no}
              onChange={() =>
                setFormState((prev) => ({
                  contactId: prev.contactId,
                  cardInPossession: prev.cardInPossession,
                  typeOfLoss: prev.typeOfLoss,
                  fraudOnAccount: prev.fraudOnAccount,
                  transferToSecurity: prev.transferToSecurity,
                  cid: prev.cid,
                  bestWayToContact: prev.bestWayToContact,
                  shipReplacementTo: prev.shipReplacementTo,
                  addressChangeToday: prev.addressChangeToday,
                  newAddressVerify: prev.newAddressVerify,
                  tempAddressVerify: prev.tempAddressVerify,
                  reasonForTempAddress: prev.reasonForTempAddress,
                  cid2: prev.cid2,
                  tempAddress: prev.tempAddress,
                  replacementOption: prev.replacementOption,
                  waiveReplacementFee: false,
                  waiveRushFee: prev.waiveRushFee,
                  dateLost: prev.dateLost,
                  lossLocation: prev.lossLocation,
                  stateOfLoss: prev.stateOfLoss,
                  pinCompromised: prev.pinCompromised,
                  fraudCharges: prev.fraudCharges,
                }))
              }
            />
          </div>
          {formState.replacementOption === ReplacementType.RushFedex ||
          formState.replacementOption === ReplacementType.RushPostal ? (
            <div className={styles.row}>
              <div className={styles.label}>{strings.waiveRushFee}</div>
              <StyledRadioButton
                className={styles.leftRadioInput}
                disabled={isSubmitting}
                checked={!!formState.waiveRushFee}
                label={strings.yes}
                onChange={() =>
                  setFormState((prev) => ({
                    contactId: prev.contactId,
                    cardInPossession: prev.cardInPossession,
                    typeOfLoss: prev.typeOfLoss,
                    fraudOnAccount: prev.fraudOnAccount,
                    transferToSecurity: prev.transferToSecurity,
                    cid: prev.cid,
                    bestWayToContact: prev.bestWayToContact,
                    shipReplacementTo: prev.shipReplacementTo,
                    addressChangeToday: prev.addressChangeToday,
                    newAddressVerify: prev.newAddressVerify,
                    tempAddressVerify: prev.tempAddressVerify,
                    reasonForTempAddress: prev.reasonForTempAddress,
                    cid2: prev.cid2,
                    tempAddress: prev.tempAddress,
                    replacementOption: prev.replacementOption,
                    waiveReplacementFee: prev.waiveReplacementFee,
                    waiveRushFee: true,
                    dateLost: prev.dateLost,
                    lossLocation: prev.lossLocation,
                    stateOfLoss: prev.stateOfLoss,
                    pinCompromised: prev.pinCompromised,
                    fraudCharges: prev.fraudCharges,
                  }))
                }
              />
              <StyledRadioButton
                disabled={isSubmitting}
                checked={
                  formState.waiveRushFee !== undefined &&
                  !formState.waiveRushFee
                }
                label={strings.no}
                onChange={() =>
                  setFormState((prev) => ({
                    contactId: prev.contactId,
                    cardInPossession: prev.cardInPossession,
                    typeOfLoss: prev.typeOfLoss,
                    fraudOnAccount: prev.fraudOnAccount,
                    transferToSecurity: prev.transferToSecurity,
                    cid: prev.cid,
                    bestWayToContact: prev.bestWayToContact,
                    shipReplacementTo: prev.shipReplacementTo,
                    addressChangeToday: prev.addressChangeToday,
                    newAddressVerify: prev.newAddressVerify,
                    tempAddressVerify: prev.tempAddressVerify,
                    reasonForTempAddress: prev.reasonForTempAddress,
                    cid2: prev.cid2,
                    tempAddress: prev.tempAddress,
                    replacementOption: prev.replacementOption,
                    waiveReplacementFee: prev.waiveReplacementFee,
                    waiveRushFee: false,
                    dateLost: prev.dateLost,
                    lossLocation: prev.lossLocation,
                    stateOfLoss: prev.stateOfLoss,
                    pinCompromised: prev.pinCompromised,
                    fraudCharges: prev.fraudCharges,
                  }))
                }
              />
            </div>
          ) : null}
          <div className={styles.row}>
            <DateInput
              disabled={isSubmitting}
              label={strings.dateLost}
              labelClassName={styles.label}
              inputClassName={styles.dateInput}
              value={formState.dateLost ?? ""}
              onChangeDate={(value) =>
                setFormState((prev) => ({
                  contactId: prev.contactId,
                  cardInPossession: prev.cardInPossession,
                  typeOfLoss: prev.typeOfLoss,
                  fraudOnAccount: prev.fraudOnAccount,
                  transferToSecurity: prev.transferToSecurity,
                  cid: prev.cid,
                  bestWayToContact: prev.bestWayToContact,
                  shipReplacementTo: prev.shipReplacementTo,
                  addressChangeToday: prev.addressChangeToday,
                  newAddressVerify: prev.newAddressVerify,
                  tempAddressVerify: prev.tempAddressVerify,
                  reasonForTempAddress: prev.reasonForTempAddress,
                  cid2: prev.cid2,
                  tempAddress: prev.tempAddress,
                  replacementOption: prev.replacementOption,
                  waiveReplacementFee: prev.waiveReplacementFee,
                  waiveRushFee: prev.waiveRushFee,
                  dateLost: value,
                  lossLocation: prev.lossLocation,
                  stateOfLoss: prev.stateOfLoss,
                  pinCompromised: prev.pinCompromised,
                  fraudCharges: prev.fraudCharges,
                }))
              }
            />
          </div>
          <Select
            className={classNames(styles.row, styles.shortWidth)}
            disabled={isSubmitting}
            label={strings.lossLocation}
            labelClassName={styles.label}
            placeholder={strings.select}
            options={options.lossLocations.map((ll) => ({
              value: ll.id,
              label: ll.value,
            }))}
            selectedValue={formState.lossLocation}
            onChange={(value) =>
              setFormState((prev) => ({
                contactId: prev.contactId,
                cardInPossession: prev.cardInPossession,
                typeOfLoss: prev.typeOfLoss,
                fraudOnAccount: prev.fraudOnAccount,
                transferToSecurity: prev.transferToSecurity,
                cid: prev.cid,
                bestWayToContact: prev.bestWayToContact,
                shipReplacementTo: prev.shipReplacementTo,
                addressChangeToday: prev.addressChangeToday,
                newAddressVerify: prev.newAddressVerify,
                tempAddressVerify: prev.tempAddressVerify,
                reasonForTempAddress: prev.reasonForTempAddress,
                cid2: prev.cid2,
                tempAddress: prev.tempAddress,
                replacementOption: prev.replacementOption,
                waiveReplacementFee: prev.waiveReplacementFee,
                waiveRushFee: prev.waiveRushFee,
                dateLost: prev.dateLost,
                lossLocation: value,
                stateOfLoss: prev.stateOfLoss,
                pinCompromised: prev.pinCompromised,
                fraudCharges: prev.fraudCharges,
              }))
            }
          />
          <Select
            className={classNames(styles.row, styles.shortWidth)}
            disabled={isSubmitting}
            label={strings.stateOfLoss}
            labelClassName={styles.label}
            placeholder={strings.select}
            options={options.lossStates.map((ls) => ({
              value: ls.id,
              label: ls.value,
            }))}
            selectedValue={formState.stateOfLoss}
            onChange={(value) =>
              setFormState((prev) => ({
                contactId: prev.contactId,
                cardInPossession: prev.cardInPossession,
                typeOfLoss: prev.typeOfLoss,
                fraudOnAccount: prev.fraudOnAccount,
                transferToSecurity: prev.transferToSecurity,
                cid: prev.cid,
                bestWayToContact: prev.bestWayToContact,
                shipReplacementTo: prev.shipReplacementTo,
                addressChangeToday: prev.addressChangeToday,
                newAddressVerify: prev.newAddressVerify,
                tempAddressVerify: prev.tempAddressVerify,
                reasonForTempAddress: prev.reasonForTempAddress,
                cid2: prev.cid2,
                tempAddress: prev.tempAddress,
                replacementOption: prev.replacementOption,
                waiveReplacementFee: prev.waiveReplacementFee,
                waiveRushFee: prev.waiveRushFee,
                dateLost: prev.dateLost,
                lossLocation: prev.lossLocation,
                stateOfLoss: value,
                pinCompromised: prev.pinCompromised,
                fraudCharges: prev.fraudCharges,
              }))
            }
          />
          <div className={styles.row}>
            <div className={styles.label}>{strings.pinCompromised}</div>
            <StyledRadioButton
              className={styles.leftRadioInput}
              disabled={isSubmitting}
              checked={!!formState.pinCompromised}
              label={strings.yes}
              onChange={() =>
                setFormState((prev) => ({
                  contactId: prev.contactId,
                  cardInPossession: prev.cardInPossession,
                  typeOfLoss: prev.typeOfLoss,
                  fraudOnAccount: prev.fraudOnAccount,
                  transferToSecurity: prev.transferToSecurity,
                  cid: prev.cid,
                  bestWayToContact: prev.bestWayToContact,
                  shipReplacementTo: prev.shipReplacementTo,
                  addressChangeToday: prev.addressChangeToday,
                  newAddressVerify: prev.newAddressVerify,
                  tempAddressVerify: prev.tempAddressVerify,
                  reasonForTempAddress: prev.reasonForTempAddress,
                  cid2: prev.cid2,
                  tempAddress: prev.tempAddress,
                  replacementOption: prev.replacementOption,
                  waiveReplacementFee: prev.waiveReplacementFee,
                  waiveRushFee: prev.waiveRushFee,
                  dateLost: prev.dateLost,
                  lossLocation: prev.lossLocation,
                  stateOfLoss: prev.stateOfLoss,
                  pinCompromised: true,
                  fraudCharges: prev.fraudCharges,
                }))
              }
            />
            <StyledRadioButton
              disabled={isSubmitting}
              checked={
                formState.pinCompromised !== undefined &&
                !formState.pinCompromised
              }
              label={strings.no}
              onChange={() =>
                setFormState((prev) => ({
                  contactId: prev.contactId,
                  cardInPossession: prev.cardInPossession,
                  typeOfLoss: prev.typeOfLoss,
                  fraudOnAccount: prev.fraudOnAccount,
                  transferToSecurity: prev.transferToSecurity,
                  cid: prev.cid,
                  bestWayToContact: prev.bestWayToContact,
                  shipReplacementTo: prev.shipReplacementTo,
                  addressChangeToday: prev.addressChangeToday,
                  newAddressVerify: prev.newAddressVerify,
                  tempAddressVerify: prev.tempAddressVerify,
                  reasonForTempAddress: prev.reasonForTempAddress,
                  cid2: prev.cid2,
                  tempAddress: prev.tempAddress,
                  replacementOption: prev.replacementOption,
                  waiveReplacementFee: prev.waiveReplacementFee,
                  waiveRushFee: prev.waiveRushFee,
                  dateLost: prev.dateLost,
                  lossLocation: prev.lossLocation,
                  stateOfLoss: prev.stateOfLoss,
                  pinCompromised: false,
                  fraudCharges: prev.fraudCharges,
                }))
              }
            />
          </div>
          {formState.fraudOnAccount ? (
            <div className={styles.row}>
              <label
                className={classNames(styles.label, styles.fraudChargesLabel)}
              >
                {strings.fraudCharges}
                <textarea
                  disabled={isSubmitting}
                  className={styles.fraudChargesInput}
                  value={formState.fraudCharges ?? ""}
                  onChange={(e) =>
                    setFormState((prev) => ({
                      contactId: prev.contactId,
                      cardInPossession: prev.cardInPossession,
                      typeOfLoss: prev.typeOfLoss,
                      fraudOnAccount: prev.fraudOnAccount,
                      transferToSecurity: prev.transferToSecurity,
                      cid: prev.cid,
                      bestWayToContact: prev.bestWayToContact,
                      shipReplacementTo: prev.shipReplacementTo,
                      addressChangeToday: prev.addressChangeToday,
                      newAddressVerify: prev.newAddressVerify,
                      tempAddressVerify: prev.tempAddressVerify,
                      reasonForTempAddress: prev.reasonForTempAddress,
                      cid2: prev.cid2,
                      tempAddress: prev.tempAddress,
                      replacementOption: prev.replacementOption,
                      waiveReplacementFee: prev.waiveReplacementFee,
                      waiveRushFee: prev.waiveRushFee,
                      dateLost: prev.dateLost,
                      lossLocation: prev.lossLocation,
                      stateOfLoss: prev.stateOfLoss,
                      pinCompromised: prev.pinCompromised,
                      fraudCharges: e.target.value,
                    }))
                  }
                />
              </label>
            </div>
          ) : null}
          <div className={classNames(styles.row, styles.submitContainer)}>
            <StyledButton
              isLoading={isSubmitting}
              onClick={onSubmit}
              disabled={
                formState.waiveReplacementFee === undefined ||
                ((formState.replacementOption === ReplacementType.RushFedex ||
                  formState.replacementOption === ReplacementType.RushPostal) &&
                  formState.waiveRushFee === undefined) ||
                formState.dateLost === undefined ||
                formState.lossLocation === undefined ||
                formState.stateOfLoss === undefined ||
                formState.pinCompromised === undefined ||
                (formState.fraudOnAccount && !formState.fraudCharges)
              }
            >
              {strings.submit}
            </StyledButton>
          </div>
        </>
      ) : null}
      <BaseModal
        className={styles.modal}
        label={strings.transferToSecurityModalTitle}
        isOpen={showTransferToSecurityModal}
        onRequestClose={() => setShowTransferToSecurityModal(false)}
      >
        <img src={warningIcon} alt="" />
        <div className={styles.modalTitle}>
          {strings.transferToSecurityModalTitle}
        </div>
        <div className={styles.modalDescription}>
          {strings.transferToSecurityModalDescription}
        </div>
        <StyledButton onClick={closeTool}>{strings.closeTool}</StyledButton>
        <StyledButton
          className={styles.goBack}
          variant="secondary"
          onClick={() => setShowTransferToSecurityModal(false)}
        >
          {strings.goBack}
        </StyledButton>
      </BaseModal>
    </>
  );
}
