import { StatementHistoryStatement } from "../../../lib/api/models";
import {
  centsToDollarString,
  convertDateStringToMonthDayYearFomat,
} from "../../../lib/formatting";
import styles from "./StatementHistoryTable.module.css";

const strings = {
  statementDate: "Statement Date",
  daysInCycle: "Days in Cycle",
  dueDate: "Due Dt",
  totalAmountDue: "Total Amt Due",
  oclAmount: "OCL Amount",
  amountDelinquent: "Amount Delq",
  balance: "Bal",
  cashBalance: "Cash Bal",
  creditLimit: "Credit Limit",
  purch: "Purch",
  cash: "Cash",
  credits: "Credits",
  payments: "Pmts",
  adjs: "Adjs",
  lateFee: "Late Fee",
  oclFee: "OCL Fee",
  fcPurch: "FC Purch",
  fcCash: "FC Cash",
  adbPurch: "ADB Purch",
  adbCash: "ADB Cash",
  emptyStatements: "No Statements to display yet",
};

type Props = {
  statements: StatementHistoryStatement[];
};

export default function StatementHistoryTable({ statements }: Props) {
  return statements.length < 1 ? (
    <div className={styles.emptyStatementsContainer}>
      <div className={styles.emptyStatementsDescription}>
        {strings.emptyStatements}
      </div>
    </div>
  ) : (
    <div className={styles.outerContainer}>
      <div className={styles.tableContainer}>
        <table className={styles.statementHistoryTable}>
          <thead>
            <tr>
              <th>{strings.statementDate}</th>
              {statements.map(({ statementDate }) => (
                <th key={statementDate} tabIndex={0}>
                  {convertDateStringToMonthDayYearFomat(statementDate)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{strings.daysInCycle}</td>
              {statements.map(({ statementDate, daysInCycle }) => (
                <td
                  key={`${statementDate}-daysInCycle`}
                  className={styles.blue}
                >
                  {daysInCycle}
                </td>
              ))}
            </tr>
            <tr>
              <td>{strings.dueDate}</td>
              {statements.map(({ statementDate, dueDate }) => (
                <td key={`${statementDate}-dueDate`} className={styles.blue}>
                  {convertDateStringToMonthDayYearFomat(dueDate)}
                </td>
              ))}
            </tr>
            <tr>
              <td>{strings.totalAmountDue}</td>
              {statements.map(({ statementDate, totalAmountDueCents }) => (
                <td key={`${statementDate}-totalAmountDue`}>
                  {centsToDollarString(totalAmountDueCents)}
                </td>
              ))}
            </tr>
            <tr>
              <td>{strings.oclAmount}</td>
              {statements.map(({ statementDate, oclAmountCents }) => (
                <td key={`${statementDate}-oclAmount`}>
                  {centsToDollarString(oclAmountCents)}
                </td>
              ))}
            </tr>
            <tr>
              <td>{strings.amountDelinquent}</td>
              {statements.map(({ statementDate, delinquentAmountCents }) => (
                <td key={`${statementDate}-delinquentAmount`}>
                  {centsToDollarString(delinquentAmountCents)}
                </td>
              ))}
            </tr>
            <tr>
              <td>{strings.balance}</td>
              {statements.map(({ statementDate, balanceCents }) => (
                <td key={`${statementDate}-balance`} className={styles.blue}>
                  {centsToDollarString(balanceCents)}
                </td>
              ))}
            </tr>
            <tr>
              <td>{strings.cashBalance}</td>
              {statements.map(({ statementDate, cashBalanceCents }) => (
                <td
                  key={`${statementDate}-cashBalance`}
                  className={styles.blue}
                >
                  {centsToDollarString(cashBalanceCents)}
                </td>
              ))}
            </tr>
            <tr>
              <td>{strings.creditLimit}</td>
              {statements.map(({ statementDate, creditLimitCents }) => (
                <td
                  key={`${statementDate}-creditLimit`}
                  className={styles.blue}
                >
                  {centsToDollarString(creditLimitCents)}
                </td>
              ))}
            </tr>
            <tr>
              <td>{strings.purch}</td>
              {statements.map(
                ({ statementDate, purchaseAmountCents, purchaseCount }) => (
                  <td key={`${statementDate}-purchases`}>
                    ({purchaseCount}) {centsToDollarString(purchaseAmountCents)}
                  </td>
                ),
              )}
            </tr>
            <tr>
              <td>{strings.cash}</td>
              {statements.map(
                ({ statementDate, cashAmountCents, cashCount }) => (
                  <td key={`${statementDate}-cash`}>
                    ({cashCount}) {centsToDollarString(cashAmountCents)}
                  </td>
                ),
              )}
            </tr>
            <tr>
              <td>{strings.credits}</td>
              {statements.map(
                ({ statementDate, creditAmountCents, creditsCount }) => (
                  <td key={`${statementDate}-credits`}>
                    ({creditsCount}) {centsToDollarString(creditAmountCents)}
                  </td>
                ),
              )}
            </tr>
            <tr>
              <td>{strings.payments}</td>
              {statements.map(
                ({ statementDate, paymentAmountCents, paymentsCount }) => (
                  <td key={`${statementDate}-payments`}>
                    ({paymentsCount}) {centsToDollarString(paymentAmountCents)}
                  </td>
                ),
              )}
            </tr>
            <tr>
              <td>{strings.adjs}</td>
              {statements.map(
                ({
                  statementDate,
                  adjustmentAmountCents,
                  adjustmentsCount,
                }) => (
                  <td key={`${statementDate}-adjustments`}>
                    ({adjustmentsCount}){" "}
                    {centsToDollarString(adjustmentAmountCents)}
                  </td>
                ),
              )}
            </tr>
            <tr>
              <td>{strings.lateFee}</td>
              {statements.map(({ statementDate, lateFeeCents }) => (
                <td key={`${statementDate}-lateFee`} className={styles.blue}>
                  {centsToDollarString(lateFeeCents)}
                </td>
              ))}
            </tr>
            <tr>
              <td>{strings.oclFee}</td>
              {statements.map(({ statementDate, oclFeeCents }) => (
                <td key={`${statementDate}-oclFee`} className={styles.blue}>
                  {centsToDollarString(oclFeeCents)}
                </td>
              ))}
            </tr>
            <tr>
              <td>{strings.fcPurch}</td>
              {statements.map(({ statementDate, fcPurchaseCents }) => (
                <td key={`${statementDate}-fcPurchase`} className={styles.blue}>
                  {centsToDollarString(fcPurchaseCents)}
                </td>
              ))}
            </tr>
            <tr>
              <td>{strings.fcCash}</td>
              {statements.map(({ statementDate, fcCashCents }) => (
                <td key={`${statementDate}-fcCash`} className={styles.blue}>
                  {centsToDollarString(fcCashCents)}
                </td>
              ))}
            </tr>
            <tr>
              <td>{strings.adbPurch}</td>
              {statements.map(({ statementDate, adbPurchaseCents }) => (
                <td key={`${statementDate}-adbPurchase`}>
                  {centsToDollarString(adbPurchaseCents)}
                </td>
              ))}
            </tr>
            <tr>
              <td>{strings.adbCash}</td>
              {statements.map(({ statementDate, adbCashCents }) => (
                <td key={`${statementDate}-adbCash`}>
                  {centsToDollarString(adbCashCents)}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
